export const environment = {
  production: true,
  readyConfig: {
    serverUrl: 'https://api.demo2.readytopay.co',
    leaseExpiryDuration: 60 * 1000,
    leaseRenewalDuration: 30 * 1000,
    favicon: 'favicon.png'
  },
  firebaseConfig: {
    apiKey: 'AIzaSyAauwL8v2W1WYh3S5FSKJUoI8_-OzOiJdg',
    authDomain: 'readytopay.firebaseapp.com',
    databaseURL: 'https://readytopay.firebaseio.com',
    storageBucket: 'firebase-readytopay.appspot.com',
    messagingSenderId: '2134214'
  },
  readyDomains: ['READYDEMO.CO', 'LOCALHOST', 'NGROK.IO', 'BS-LOCAL.COM'],
  AmsConfig: {
    serviceUrl: 'https://amsint.iqmetrix.net'
  },
  AdyenConfig: {
    serviceDomain: 'adyen.com',
    clientKey: 'test_BZIOSTCMPZDOLKPJFQFOCCBXXUDWQCFV',
    environment: 'TEST',
    googlePayEnvironment: 'TEST'
  },
  StripeConfig: {
    publishableKey: 'pk_test_hONyoMTFCxHkbs4pFb6Bz45Q'
  },
  IQPaymentsConfig: {
    merchantId: 'merchant.com.paymentservicesgateway',
    hostedFormsUrl: 'https://hostedpaymentformtest12.iqmetrix.net'
  },
  FreedomPayConfig: {
    serviceDomain: 'hpc.uat.freedompay.com'
  },
  RealexConfig: {
    hppUrl: 'https://pay.sandbox.realexpayments.com/pay'
  },
  SquareConfig: {
    applicationId: 'sandbox-sq0idb-6XOudFJfrTv4CSEyYnQn5w'
  },
  DynamicPaymentProcessorApiScripts: {
    freedompay: ['https://hpc.uat.freedompay.com/api/v1.5/cdn/applepay_min.js'],
    square: ['https://sandbox.web.squarecdn.com/v1/square.js']
  },
  RecaptchaConfig: {
    siteKey: '6Ld8mjgcAAAAAGhZOHWI5YNG1EGVBXMAPYqbOB86'
  },
  env: 'demo',
  GoogleAnalyticsId: 'UA-68293320-3',
  GoogleAnalytics4MeasurementId: 'G-KS58YW22NF',
  RaygunApiKey: 'IOflLMokYxx4DnUCOvKWcw==',
  ExperimentRunning: false,
  EventPollingIntervalMilliseconds: 60000
};
